import {
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import type { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FiChevronDown as ChevronDownIcon,
  FiChevronRight as ChevronRightIcon,
} from 'react-icons/fi';
import {
  IoClose as CloseIcon,
  IoMenuOutline as HamburgerIcon,
} from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { sections } from 'config';
import { scrollToElById } from 'shared/scroll';

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  icon?: ComponentWithAs<'svg', IconProps>;
}

function Header() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation(['common']);

  const navItems: Array<NavItem> = [
    {
      label: t('common:header.nav_item.home'),
      href: '#',
    },
    {
      label: t('common:header.nav_item.service'),
      href: sections.service,
    },
    // {
    //   label: t('common:header.nav_item.application'),
    //   href: sections.application,
    // },
    {
      label: t('common:header.nav_item.event'),
      href: sections.event,
    },
    {
      label: t('common:header.nav_item.team'),
      href: sections.team,
    },
    {
      label: t('common:header.nav_item.contact'),
      href: sections.contact,
    },
  ];

  return (
    <Box zIndex='docked'>
      <Flex
        justifyContent={{ base: 'space-between' }}
        bg={useColorModeValue('neutral.10', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        h={{ base: '56px', lg: '72px' }}
        py={{ base: '12px', lg: '16px' }}
        px={{ base: '24px', lg: '140px' }}
        borderBottom={1}
        borderStyle='solid'
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align='center'
        boxShadow='0px 2px 8px rgba(51, 65, 85, 0.08)'>
        <Flex justify='start' flex={{ base: 'auto', lg: '1' }}>
          <Link to='/'>
            <Image
              src='/assets/images/logo_dummy.png' //TODO: Temporary logo
              h={{ base: '32px', lg: '40px' }}
            />
          </Link>

          <Flex display={{ base: 'none', lg: 'flex' }} ml='auto'>
            <DesktopNav navItems={navItems} />
            <a
              href='https://wa.me/6285375850455'
              target='_blank'
              rel='noreferrer'>
              <Button
                ml='32px'
                display={{ base: 'none', lg: 'inline-flex' }}
                fontSize='sm'
                color='white'
                textTransform='uppercase'
                letterSpacing='1.25px'
                bgColor='#FCA311'
                _hover={{
                  bgColor: '#FCA311',
                }}>
                {t('common:header.cta_button_label')}
              </Button>
            </a>
          </Flex>
        </Flex>
        <Flex ml={{ base: -2 }} display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={<Icon as={HamburgerIcon} w={9} h={9} color='#FCA311' />}
            variant='ghost'
            aria-label='Toggle Navigation'
          />
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='full'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            px={4}
            h='56px'
            display='flex'
            justifyContent='space-between'
            alignItems='center'>
            <Link to='/'>
              <Image src='/assets//images/logo.png' h='32px' />
            </Link>
            <IconButton
              onClick={onClose}
              icon={<Icon as={CloseIcon} w={9} h={9} color='green.60' />}
              variant='ghost'
              aria-label='Close mobile navbar'
            />
          </DrawerHeader>
          <DrawerBody pt='0' px={4}>
            <MobileNav navItems={navItems} onClickItem={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

type DesktopNavProps = {
  navItems: Array<NavItem>;
};

function DesktopNav({ navItems }: DesktopNavProps) {
  return (
    <Stack direction='row' spacing='0'>
      {navItems.map((navItem) => {
        return (
          <Flex key={navItem.label}>
            <Popover trigger='hover' placement='bottom-start'>
              <PopoverTrigger>
                <Flex
                  onClick={() => navItem.href && scrollToElById(navItem.href)}
                  role='group'
                  px='16px'
                  position='relative'
                  flexDir='column'
                  justifyContent='center'
                  alignItems='center'
                  color='neutral.90'
                  cursor='pointer'
                  _hover={{
                    color: 'neutral.100',
                  }}>
                  <Text fontSize='sm' fontWeight={400}>
                    {navItem.label}
                  </Text>
                  <Box
                    display='none'
                    position='absolute'
                    bottom='-16px'
                    w='100%'
                    h='4px'
                    bgColor='green.50'
                    _groupHover={{
                      display: 'block',
                    }}
                  />
                </Flex>
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow='xl'
                  bg='gray.800'
                  p={4}
                  rounded='xl'
                  minW='sm'>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Flex>
        );
      })}
    </Stack>
  );
}

function DesktopSubNav({ label, href, subLabel }: NavItem) {
  return (
    <Link to={href ?? '#'} role='group'>
      <Stack direction='row' align='center'>
        <Box>
          <Text
            transition='all .3s ease'
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize='sm'>{subLabel}</Text>
        </Box>
        <Flex
          transition='all .3s ease'
          transform='translateX(-10px)'
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify='flex-end'
          align='center'
          flex={1}>
          <Icon color='pink.400' w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
}

type MobileNavItemProps = {
  navItems: Array<NavItem>;
  onClickItem?: () => void;
};

function MobileNav({ navItems, onClickItem }: MobileNavItemProps) {
  const { t } = useTranslation(['common']);

  return (
    <Flex flexDir='column' display={{ lg: 'none' }}>
      <Stack>
        {navItems.map((navItem) => (
          <MobileNavItem
            key={navItem.label}
            onClick={onClickItem}
            {...navItem}
          />
        ))}
      </Stack>
      <a href='https://wa.me/6285375850455' target='_blank' rel='noreferrer'>
        <Button
          size='lg'
          mt='16px'
          color='white'
          fontSize='14px'
          fontWeight={700}
          w='100%'
          borderRadius='8px'
          textTransform='uppercase'
          letterSpacing='1.25px'
          bgColor='green.60'
          _hover={{
            bgColor: 'green.70',
          }}>
          {t('common:header.cta_button_label')}
        </Button>
      </a>
    </Flex>
  );
}

function MobileNavItem({
  label,
  children,
  href,
  onClick,
}: NavItem & { onClick?: () => void }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        onClick={() => {
          if (href) {
            onClick?.();
            setTimeout(() => {
              scrollToElById(href);
            }, 150);
          }
        }}
        justify='space-between'
        align='center'
        _hover={{
          textDecoration: 'none',
        }}>
        <HStack spacing='8px'>
          <Text fontWeight={400} color='neutral.90'>
            {label}
          </Text>
        </HStack>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition='all .25s ease-in-out'
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse
        in={isOpen}
        animateOpacity={true}
        style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle='solid'
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align='start'>
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                to={child.href ?? '#'}
                style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

export { Header };
