export const socials = {
  whatsapp: 'https://wa.me/6285375850455/',
  instagram: 'https://www.instagram.com/dgscope.id/',
  linkedin: 'https://www.linkedin.com/in/dgscope.id/',
  email: 'dgscope.id@gmail.com',
};

export const sections = {
  hero: 'hero',
  service: 'service',
  // application: 'application',
  event: 'event',
  team: 'team',
  contact: 'contact',
};
