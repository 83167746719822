import {
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { ComponentWithAs, IconProps } from '@chakra-ui/react';

import { sections, socials } from 'config';

import { EnvelopeOutlineIcon } from '../icon/EnvelopeOutlineIcon';
import { InstagramOutlineIcon } from '../icon/InstagramOutlineIcon';
import { LinkedinOutlineIcon } from '../icon/LinkedinOutlineIcon';
import { WhatsappOutlineIcon } from '../icon/WhatsappOutlineIcon';

import { Container } from './Container';

type Social = {
  text: string;
  icons: Array<IconType>;
};

type IconType = {
  icon: ComponentWithAs<'svg', IconProps>;
  url: string;
};

const SOCIALS: Array<Social> = [
  {
    text: 'M3DTOOLS',
    icons: [
      {
        icon: InstagramOutlineIcon,
        url: socials.instagram,
      },
      {
        icon: LinkedinOutlineIcon,
        url: socials.linkedin,
      },
    ],
  },
  {
    text: '0853-7585-0455',
    icons: [
      {
        icon: WhatsappOutlineIcon,
        url: socials.whatsapp,
      },
    ],
  },
  {
    text: 'm3dtools.id@gmail.com',
    icons: [
      {
        icon: EnvelopeOutlineIcon,
        url: socials.email,
      },
    ],
  },
];

function Footer() {
  return (
    <Container
      id={sections.contact}
      pt='40px'
      pb={{ base: '64px', lg: '142px' }}
      flexDir='column'
      w='100%'
      bgColor='neutral.80'
      textAlign='center'
      justifyContent='center'
      position='relative'>
      <Image
        src='/assets/images/footer-bg-sm-right-bottom.png'
        position='absolute'
        display={{ base: 'block', md: 'none' }}
        w={{ base: '100%', md: 'auto' }}
        right='0'
        bottom='0'
        zIndex={0}
        objectFit='contain'
      />
      {/* <Image
        src='/assets/images/footer-bg-md-right-bottom.png'
        position='absolute'
        display={{ base: 'none', md: 'block' }}
        right='0'
        bottom='0'
        zIndex={0}
        objectFit='contain'
      />
      <Image
        src='/assets/images/footer-bg-left-bottom.png'
        display={{ base: 'none', md: 'block' }}
        position='absolute'
        left='0'
        bottom='0'
        zIndex={0}
        objectFit='contain'
      /> */}
      <Heading
        color='neutral.50'
        textAlign='center'
        fontSize={{ base: '24px', lg: '32px' }}
        fontWeight={700}
        zIndex={1}>
        Hubungi Kami
      </Heading>
      <SimpleGrid
        mt='64px'
        columns={{ base: 1, lg: 3 }}
        spacing='24px'
        zIndex={1}>
        {SOCIALS.map((item, i) => (
          <Stack
            key={i}
            direction='row'
            spacing='16px'
            alignItems='center'
            justifyContent='center'
            bgColor='neutral.70'
            w='100%'
            borderRadius='16px'
            px='24px'
            py='16px'>
            <Stack direction='row' spacing='2px'>
              {item.icons.map((icon, j) => (
                <a key={j} href={icon.url} target='_blank' rel='noreferrer'>
                  <Icon
                    as={icon.icon}
                    color='white'
                    fill='none'
                    w='32px'
                    h='32px'
                  />
                </a>
              ))}
            </Stack>
            <Text fontSize='16px' fontWeight={600} color='white'>
              {item.text}
            </Text>
          </Stack>
        ))}
      </SimpleGrid>
      <Text
        fontSize='md'
        color='neutral.30'
        marginTop='48px'>
          Copyright 2023 @ PT. Digitalindo Medikatekno Lantaburo
      </Text>
    </Container>
  );
}

export { Footer };
